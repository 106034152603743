import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from 'react-router-dom';
import SolutionModal from './SolutionModals';

import HeaderLogo from '../../assets/Solutions/MedhaHeader.png';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  linkText: {
    color: '#696969',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
}));

export default function Medha() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column' style={{paddingBottom: '5vh'}}>
      <SolutionModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' style={{fontWeight: '700', fontSize: '4rem',fontFamily: 'monospace'}}>
          Medha
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>

        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          <span style={{fontWeight: '700'}}>
            Medha 
          </span>
          <span style={{color: '#fff'}}>
            .
          </span>
          <span>
           is an AI solution for recruiting agencies to get right candidates by ranking candidate profiles based on proprietary 
          </span>
          <span style={{color: '#fff'}}>
            .
          </span>
          <span style={{fontWeight: '700'}}>
            ‘Medha Score’
          </span>
          <span style={{color: '#fff'}}>
            .
          </span>
          <span>
            by processing the following components of candidate profile:
          </span>
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Video/Audio Resume
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Cover Letter
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Text Resume
        </Typography>

        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>

        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Features
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Allows candidate to upload profile using Video Resume/Audio Resume, Cover Letter, Text Resume
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Allows recruiting agency to query for profiles using Soft Skills (Emotion Levels, Confidence Levels, Communication Skills) and Technical Skills
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Ranks profiles using Proprietary “Medha Score” and fetches the profiles based on the query
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Multi-lingual support (Video Resume can be in English, Spanish, Hindi)
        </Typography>


      </Grid>
      
    
    </Grid>
  );
}
