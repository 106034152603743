import React from 'react';
import logo from '../assets/long_logo.png';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import '../theme/styles.css';
// import {ThemeProvider} from "styled-components";
// import { GlobalStyles } from "../theme/GlobalStyles";


const NavBar = () => {
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Navbar collapseOnSelect expand="lg" style={{ fontFamily: 'Raleway', fontWeight: '600', backgroundColor: '#617087', width: '100vw !important' }}>
            <Navbar.Brand>
                <a href="/">
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            width: matchesSM ? 200 : 300,
                        }}
                    />
                </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {/* <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                <Nav.Link href="/" style={{color: '#fff'}}>Home</Nav.Link>
                <Nav.Link href="/#expertise" style={{color: '#fff'}}>Expertise</Nav.Link>
                <Nav.Link href="/#services" style={{color: '#fff'}}>Services</Nav.Link>
                <Nav.Link href="/solutions" style={{color: '#fff'}}>Solutions</Nav.Link>
                <Nav.Link href="/blogs" style={{color: '#fff'}}>Blogs</Nav.Link>
                <Nav.Link href="/#contacts" style={{color: '#fff'}}>Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse> */}
            <Navbar.Collapse id="responsive-navbar-nav" className="mr-5">
                <Nav className="ml-auto">
                    <Nav.Link href="/" style={{ color: '#000' }}>Home</Nav.Link>
                    <NavDropdown title="Offerings" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="/#expertise">Expertise</NavDropdown.Item>
                        <NavDropdown.Item href="/#services">Services</NavDropdown.Item>
                        <NavDropdown.Item href="/solutions">Solutions</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link href="/blogs" style={{ color: '#000' }}>Blogs</Nav.Link>
                    <Nav.Link href="/#contacts" style={{ color: '#000' }}>Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar;
