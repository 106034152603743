import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import HeaderLogo from '../../assets/Blogs/Video_Resume/headerImage.png';
import AISolution from '../../assets/Blogs/Video_Resume/aiSolution.jpeg';
import AIModel from '../../assets/Blogs/Video_Resume/aiModel.png';

import BlogModal from './BlogModals';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em !important',
    }
  },
}));

export default function VideoResume() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <BlogModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' className={classes.headingText} style={{fontSize: '3.7em', fontWeight: '700', fontFamily: 'monospace'}}>
          Video Resume Analysis
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 250 : '60em',
              marginBottom: matchesMD ? '2vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Business Problem
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Finding out top K Video Resumes by processing thousands/ hundreds of Video Resumes based on certain keywords/ tags.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Predict most appropriate keywords/ tags for given video resume.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '5vh', textAlign: 'justify'}} gutterBottom>
          Arrange or score all the Video Resumes based on given keywords/ tags.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Technology Stack
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}}>
          Language: 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh'}} gutterBottom>
          Python
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}}>
          Databases: 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh'}} gutterBottom>
          MySQL/ MongoDB
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}}>
          Deep Learning Frameworks: 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh'}} gutterBottom>
          TensorFlow, Pytorch, Caffe
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}}>
          Cloud Platform: 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh'}} gutterBottom>
          AWS/ GCP/ Azure
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '5vh', fontWeight: '700'}} gutterBottom>
          AI Solution
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={AISolution}
            alt="startup img"
            style={{
              width: matchesSM ? 250 : '60em',
              marginBottom: matchesMD ? '2vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          AI Model
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={AIModel}
            alt="startup img"
            style={{
              width: matchesSM ? 250 : '60em',
              marginBottom: matchesMD ? '2vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Filtering & Scoring Video Resumes
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          1) Output of all the Analysis (Video, Audio, Transcribe) will be in JSON Format.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          2) Based on keys, Filtering will be very Quick and Easy.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          3) Based on how many keys matched with the Video Resume, we can assign score for each key match. (Default: 1 key match will get 1 Score point).
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          4) We can also give more score for certain keys. E.g. For Work Experience you can assign 2 score points.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          5) Once all the Video Resumes get Score points, sort all the Video Resumes based on Score points.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '5vh', textAlign: 'justify'}} gutterBottom>
          6) Display top k video Resumes from sorted Video Resumes.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Value Proposition
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          1) Reduction in Human Effort and Time: Saves Time & Effort in watching thousands/ hundreds of Video Resumes.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '5vh', textAlign: 'justify'}} gutterBottom>
          2) Quick & Easy to filter and sorting: Quick & Easy to filter and sorting the Video Resumes based on keywords/ tags and Score points.
        </Typography>        
      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        
      </Grid>      
    </Grid>
  );
}



// <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
//   Expected Challenges
// </Typography>
// <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
//   1) Solution should not take more time to process and generate keywords/ tags for single Video Resume. Otherwise, it will take Huge amount of time to process thousands/ hundreds of Video Resumes.
// </Typography>
// <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
//   2) Solution should be accurate. If not then it will lose good candidates at the same time non-relevant candidates will get chance.
// </Typography>