import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BlogModal from './BlogModals';

import HeaderLogo from '../../assets/Blogs/Yoga/headerI.gif';
import GetStarted from '../../assets/Blogs/Yoga/getStarted.gif';
import Cell1 from '../../assets/Blogs/Yoga/Cell1.png';
import Cell2 from '../../assets/Blogs/Yoga/Cell2.png';
import Cell3 from '../../assets/Blogs/Yoga/Cell3.png';
import Cell4 from '../../assets/Blogs/Yoga/Cell4.png';
import Cell5 from '../../assets/Blogs/Yoga/Cell5.png';
import Cell6 from '../../assets/Blogs/Yoga/Cell6.png';
import Cell7 from '../../assets/Blogs/Yoga/Cell7.png';
import Cell8 from '../../assets/Blogs/Yoga/Cell8.png';
import Flut1 from '../../assets/Blogs/Yoga/flut1.png';
import Flut2 from '../../assets/Blogs/Yoga/flut2.png';
import Flut3 from '../../assets/Blogs/Yoga/flut3.png';
import Flut4 from '../../assets/Blogs/Yoga/flut4.png';
import Flut5 from '../../assets/Blogs/Yoga/flut5.png';
import Flut6 from '../../assets/Blogs/Yoga/flut6.png';
import Flut7 from '../../assets/Blogs/Yoga/flut7.png';
import Flut8 from '../../assets/Blogs/Yoga/flut8.png';
import Flut9 from '../../assets/Blogs/Yoga/flut9.png';
import Output1 from '../../assets/Blogs/Yoga/Output1.png';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3em !important',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify'
    }
  },
}));

export default function YogaDetection() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <BlogModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' className={classes.headingText}  style={{fontSize: '3.7em', fontWeight: '700', fontFamily: 'monospace'}} gutterBottom>
          Yoga Detection Using Flutter and Tensorflow
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '700', color: '#888', fontFamily: 'monospace'}} gutterBottom>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process)
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Machine learning and Artificial Intelligence take the development of mobile applications to a new level. Apps that use machine learning can detect speech, images, and body language. AI gives us new and compelling ways to engage and connect with people in the world around us. But how do we integrate machine learning into our mobile applications?
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Developing mobile applications that include machine learning has been a difficult task for a long time. But dev platforms and tools like Firebase’s ML, and TensorFlow Lite have made it a piece of cake. These tools provide users with pre-trained machine learning models as well as tools for training and importing traditional models. But how do we develop a compelling experience on top of those machine learning models? That’s where Flutter enters.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Developing mobile applications that include machine learning has been a difficult task for a long time. But dev platforms and tools like Firebase’s ML, and TensorFlow Lite have made it a piece of cake. These tools provide users with pre-trained machine learning models as well as tools for training and importing traditional models. But how do we develop a compelling experience on top of those machine learning models? That’s where Flutter enters.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          In this article, we will combine the power of Flutter and on-device ML to develop a Flutter application that can detect Yoga pose.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '2vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Let’s have a quick look at what we are gonna build today:
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Get the Dataset:
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          <span style={{paddingRight: '0.5vw'}}>
            You can download the dataset from 
          </span>
          <a href={'https://www.kaggle.com/niharika41298/yoga-poses-dataset'}>
            here
          </a>
          <span style={{paddingRight: '0.3vw'}}>
            .
          </span>
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          This article will be divided into 2 modules:
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Training a CNN(Convolutional Neural Network)
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Creating Flutter UI and Importing the .tflite file(ML Model) into the Flutter project
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={GetStarted}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Module 1: Training Neural Networks
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          We are using Tensorflow and Keras to train the model.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', fontFamily: 'monospace', textAlign: 'justify'}} gutterBottom>
          What is TensorFlow? → TensorFlow is an open-source artificial intelligence library, using data flow graphs to build models. It allows developers to create large-scale neural networks with many layers. TensorFlow is mainly used for: Classification, Perception, Understanding, Discovering, Prediction and Creation.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Let’s start by opening Jupyter Notebook (or Google Colab):
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell1}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ The os module will provide us functions for fetching contents of and writing to a directory.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Set the base_dir variable to the location of the dataset containing the training images.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell2}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ IMAGE_SIZE = 224 → the image size that we are going to set the images the dataset to.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ BATCH_SIZE = 64 → the number of images we are inputting into the neural network at once.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ rescale=1./255 reduces the file size, to reduce the training time.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Datasets have a Test set and a Training set. The training set is to train our model and the test(validation) set is to measure how accurate our model is. So with validation_split=0.2, we are telling Keras to use 80% for training and 20% for accuracy testing (validation).
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Then, we have two generators (train_generator and val_generator), which take the path to the directory & generate batches of augmented data, which in this case give the output: Found 2872 images belonging to 36 classes and Found 709 images belonging to 36 classes.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell3}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Print all keys and classes (labels) of the dataset to re-check if everything is working fine.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Flutter requires two files: model.tflite and labels.txt .
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ The ‘w’ in the code creates a new file called labels.txt having the labels(names of animals), which if already exists, then overwrites it.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          Now we will use MobileNetV2, which is a convolutional neural network architecture that seeks to perform well on mobile devices. It is based on an inverted residual structure where the residual connections are between the bottleneck layers.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell4}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ In our case, the fully connected output layers of the model used to make predictions are not loaded, allowing a new output layer to be added and trained. So, we are setting the include_top argument to False.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell5}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ base_model.trainable=False to freeze all the weights before compiling the model.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Now we will add our hidden layers:
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          1. Convo2D is a 2D convolution layer that creates a convolution kernel that is a wind with layers input which helps produce a tensor of outputs. It is trying to understand the image’s patterns.
          ▹ ‘relu’ stands for rectified linear unit activation function. It is a piecewise linear function that will output the input directly if it is positive, otherwise, it will output zero.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          2. Dropout layer prevents Neural Networks from Overfitting, i.e being too precise to a point where the NN is only able to recognize images that are present in the dataset and no other images.
          ▹ The Dropout layer randomly sets input units to 0 with a frequency of rate at each step during training time, which helps prevent overfitting.
          ▹ Note that the Dropout layer only applies when training is set to True such that no values are dropped during inference.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          3. GlobalAveragePooling2D layer calculates the average output of each feature map in the previous layer, thus reducing the data significantly and preparing the model for the final layer.
          ▹ The 2D Global average pooling block takes a tensor of size (input width) x (input height) x (input channels) and computes the average value of all values across the entire (input width) x (input height) matrix for each of the (input channels).
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          4. Dense layer is a deeply connected layer in which each neuron receives input from all neurons of its previous layer. ‘5’ here stands for the number of classes (here types of animals).
          ▹ ‘softmax’ converts a real vector to a vector of categorical probabilities.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell6}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Before training the model we need to compile it and define the loss function, optimizers, and metrics for prediction. So, we use model.compilewhich defines the loss function, the optimizer, and the metrics, because a compiled model is needed to train (since training uses the loss function and the optimizer).
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ We will use Adam which is a popular optimizer, designed specifically for training deep neural networks. Adam is a replacement optimization algorithm for stochastic gradient descent for training deep learning models.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Cell7}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Epochs → An epoch means training the neural network with all the training data for one cycle. In an epoch, we use all of the data exactly once. A forward pass and a backward pass together are counted as one pass: An epoch is made up of one or more batches, where we use a part of the dataset to train the neural network.
          ▹ Higher the number, the more accurate the neural network, but having the number too high could cause Overfitting, i.e being too precise to a point where the NN is only able to recognize images that are present in the dataset and no other images.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Output1}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          Now we have to convert our Neural Network Model to a .tflite file which we can use in our Flutter App.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh', textAlign: 'justify'}}>
          <img
            src={Cell8}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Keras SavedModel uses tf.saved_model.save to save the model and all trackable objects attached to the model
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ To convert a SavedModel to a TensorFlow Lite model we use tf.lite.TFLiteConverter.from_saved_model.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          If you are using Google Colab, then at first upload the dataset.zip file to drive, mount the drive, extract files using colab and use it. Finally you can download the model.tflite and labels.txt file by using following codes: from google.colab import files files.download(‘model.tflite’) files.download(‘labels.txt’)
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ You can find my Jupyter Notebook from here and Google Colab file from here.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Module 2: Importing and using TensorFlow Lite file in our Flutter app
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          Open terminal, then navigate to your project directory and run the command ‘flutter run project_name’ or if you are using Visual Studio Code, then open command palette from ‘view’ of topbar (or by pressing ctrl+shift+P) and choose the option for flutter to create a new app and write the project name and hit Enter.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          Next, head over to the ‘pubspec.yaml’, add the following dependencies, and save (may be you have to run 'flutter pub get’ command, by this command flutter saves the concrete package version found in the pubspec.lock lockfile):
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          dependencies:
          flutter:
            sdk: flutter
          tflite: ^1.1.2
          image_picker: ^0.8.3+2
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          For tflite to work, in android/app/build.gradle, set minSdkVersion to 19 and add the following setting in android block.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Create a folder named “assets” and place the model.tflite & labels.txt files within it. Then add their existency in pubspec.yaml file like following.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut1}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ In the ‘main.dart’ file, return MaterialApp that has a parameter home: Home()
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut2}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Then create a new ‘home.dart’ file having the Stateful class Home(). This will be our homepage. Let’s start making the Functional Flutter App by importing the necessary packages and creating the functions:
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut3}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          👉 _loading → used to check if an image has been chosen or not
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          👉 _image → image that is chosen from gallery or camera
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee'}} gutterBottom>
          👉 _output → prediction made by TensorFlow Model
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          👉 picker → allows us to pick an image from gallery or camera.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Next, we will write 6 different methods for the class:
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut4}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          initState() → This is the first method that is called when the Home widget is created i.e we the app is launched and navigated to Home(), before actually building the widget itself, anything inside initState() function will be called or initialized first and the widgets are built later. In this case, we will load our model using loadModel(), which is another method that will be written later. After that, we will pass in a value.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          dispose() → This method disposes and clears our memory.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut5}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          1. classifyImage() → this method runs the classification model on the image. The numResults is the number of classes (here the number of animals) we have, then adding setState to save changes.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          2. loadModel() → this function will load our model, hence we put it inside the initS method.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          3. pickImage() → this function is used to grab the image from the camera.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          4. pickGalleryImage() → this function is used to grab the image from the user’s gallery.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Let’s create the AppBar:
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut6}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Now it’s time for the body part of our homepage. Let’s make a container to hold an image that the user has selected.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut7}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ We have used ClipRRect to give nice circular borders to the image.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut8}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Next, we are going to make two GestureDetectors, that onTap: refer to the pickImage and the pickGalleryImage function respectively.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Flut9}
            alt="img"
            style={{
              width: matchesSM ? 350 : '40em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#eee', textAlign: 'justify'}} gutterBottom>
          Done, now run the project: In the terminal run the command "flutter run"
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '1vh', textAlign: 'justify'}} gutterBottom>
          ▹ Once you get the hang of it, you can see how easy is it to use TensorFlow Lite with Flutter to develop proof-of-concept machine learning mobile applications. It’s incredible to make this very powerful app with this small piece of code. To improve your knowledge, you can visit the Kaggle site and download various datasets to develop different classification models and use those in your own app.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Now you can feel the power of AI & Flutter 😉
        </Typography>


      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        
      </Grid>
    </Grid>
  );
}

