import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BlogModal from './BlogModals';

import HeaderLogo from '../../assets/Blogs/Chatbot/Img1.png';
import Img2 from '../../assets/Blogs/Chatbot/Img2.png';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em !important',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
}));

export default function Chatbot() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <BlogModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' className={classes.headingText}  style={{fontSize: '3.7em', fontWeight: '700', fontFamily: 'monospace'}} gutterBottom>
          Future Of Personal Assistant (Chatbot)
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Introduction :
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          In this constantly evolving world of data and technology, businesses that still manage and prefer one-on-one  conversations are considered to be outdated. Today, customers have become more critical when it comes to instantaneous forms of communication while using different messenger applications. As per the current market scenario, enhancing customer experience is the only way to increase the conversion rate. 
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Today’s buyers prefer social media over emails and other messaging tools, as they get quick information easily accessible to them at their convenience.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Because, chatbots allow buyers to find information and solutions no matter what device they use. They are well equipped to handle multiple clients along with solutions for their problems and thus increasing the customer experience.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Chatbots can be extremely helpful for a business to create a strong brand image and they will continue to evolve and perform a vital role in customer service for all different types of companies.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Need for Personal Assistants :
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Considering the above benefits of chatbots  and their business and customer support and solutions . The main aspect that they lack is personalized solutions and adaptation. Since , they were not meant for adapting and understanding the use case and planning the implementation of specific tasks. In short the chatbots are domain specific and content specific whereas the AI assistant is customer centric open- domain adaptive agent intended to help every individual in assisting in whichever domain and whatever task they are intending to perform to ease up the load and increase efficiency.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          With the growing workload and hectic schedule on every individual and the necessity to deal with several features to get a particular task streamlined . There are always many things that are always pending to be performed. In Order to address several of these works which are mostly dealt with online and to provide solutions in every situation which are not related to a single domain or entity there needs to be one who takes a note and thrives to assist in all the work we have on a day to day basis and thereby increasing productivity.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Thus there is a potential necessity for a personal agent in every domain for every individual to make all the processes streamlined and to ease up the burden.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ffbf72', textAlign: 'justify'}} gutterBottom>
          Intelligent Virtual Assistant Market Size to Reach USD 44,255 Million by 2027 at a CAGR 37.7%
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          By the way , what can a Personal Assistant do ?
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          A Personal assistant can perform plenty basic tasks but not limited to :
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Handling conversations from multiple contacts on behalf of user
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Sending mail and messages or drafting specific context.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Helping with bookings and searching for best offers
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Tracking down important members with their safety status
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Helping with browsing and getting specific content from web
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Filling up forms / applications
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Understanding the situation and helping wherever needed
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Assist in managing several pipelined tasks and pending tasks
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Explaining a concept or any information
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Recommending useful commodities / insights
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Providing news and personalised feeds
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Appliances automation
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Most importantly taking total care of the user and always looking for ways to serve and assist the user according to the situation and tasks by following ethical standards.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img2}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          An Ideal AI assistant is the one that understands your nature, preferences and the work nature and adapts itself to assist in social and professional life to increase productivity and a time for personal space to be yourself.  
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Whether you are an employee or an employer or a student , a worker or a fitness coach or a researcher or a traveller , for all these roles  that have different use cases or different need for a companion , all the requirements can be fulfilled by having a personal assistant who cares for you just like your family member and help you in every difficulty situation. There is a huge amount of research and development going on for bring into life the above mentioned AI assistant , but the main aspects or the challenges that are making us await are :
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ●	Bringing the real AI into life which can understand and create insights to process real life stochastic personalized  tasks and applications wisely.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ●	Formulating proper ethical standards and making the AI follow the standards strictly to avoid any misuse or misguided loyalty.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Self debugging platform for uninterrupted usage.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Converting user specified tasks to a list of stepwise subtasks to accomplish the given task.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Generating certain response with several data types involved.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Multimodal analysis to understand the user and surrounding.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Besides these there are several technical hurdles that have been in progress to overcome these challenges and establish a real working AI.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          One such initiative by Kaglorsys is Namaste, a personalized AI agent which is meant to purely assist, learn and provide work assistance and information to users in every aspect it can and gives users free time to be themselves. Isn’t it interesting ?
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          More updates about Namaste would be listed soon along with product specs.
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          If you feel this content is useful please do follow Kaglorsys Technologies for more such updates and the product of course. 
        </Typography>


      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        
      </Grid>
    </Grid>
  );
}

