import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';


import VideoAnalysisLogo from '../assets/Blogs/Video_Resume/headerImage.png';
import yogaDetectionLogo from '../assets/Blogs/Yoga/headerI.gif';
import deployFlasknLogo from '../assets/Blogs/Deploy_Flask_App/MediumHeader.gif';
import chatbotLogo from '../assets/Blogs/Chatbot/Img1.png';
import tableauLogo from '../assets/Blogs/Tableau/img1.png';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  linkText: {
    color: '#696969',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
}));

export default function Blogs() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column' style={{paddingBottom: '5vh'}}>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' style={{fontWeight: '700', fontSize: '4rem',fontFamily: 'monospace'}}>
          Blogs
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>
      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={yogaDetectionLogo}
          alt="startup img"
          style={{
            width: matchesSM ? 100 : '10em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/blogs/yoga">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '500'}} gutterBottom>
              Yoga Detection using Flutter & Tensorflow
            </Typography>
          </Grid>
        </Link>
      </Grid>
      
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={VideoAnalysisLogo}
          alt="startup img"
          style={{
            width: matchesSM ? 100 : '10em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/blogs/videoResume">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '500'}} gutterBottom>
              Video Resume Analysis
            </Typography>
          </Grid>
        </Link>
      </Grid>

      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={deployFlasknLogo}
          alt="startup img"
          style={{
            width: matchesSM ? 100 : '10em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/blogs/deployFlask">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '500'}} gutterBottom>
              Deploying a flask application on AWS EC2 instance for Pose-Estimation
            </Typography>
          </Grid>
        </Link>
      </Grid>

      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={chatbotLogo}
          alt="startup img"
          style={{
            width: matchesSM ? 100 : '10em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/blogs/chatbot">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '500'}} gutterBottom>
              Future Of Personal Assistant (Chatbot)
            </Typography>
          </Grid>
        </Link>
      </Grid>

      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={tableauLogo}
          alt="startup img"
          style={{
            width: matchesSM ? 100 : '10em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/blogs/tableau">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '500'}} gutterBottom>
              Prediction using Tableau with Predictive Modeling Functions
            </Typography>
          </Grid>
        </Link>
      </Grid>
      
    </Grid>
  );
}
