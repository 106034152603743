import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from 'react-router-dom';

import SolutionModal from './SolutionModals';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  linkText: {
    color: '#696969',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
}));

export default function Namaste() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column' style={{paddingBottom: '5vh'}}>
      <SolutionModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' style={{fontWeight: '700', fontSize: '4rem',fontFamily: 'monospace'}}>
          Namaste
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          AI voice based personal assistant designed to enhance user convenience and eliminate time consuming manual processes
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Features
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Conversation made extremely elegant
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Web based Task management (searching, briefing info) 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Import Google Contacts 
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Send whatsapp messages
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Send e-mail
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Send Meeting Links
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Task Tracking and Schedule management
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Play media in Youtube
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Open/Close apps (Instagram, Facebook, Twitter)
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Show map & directions
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Translate
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Set Timer
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Take screenshot
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ System/battery information
        </Typography>


      </Grid>
    </Grid>
  );
}
