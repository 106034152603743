import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import NavBar from "./components/NavBar";
import Landing from "./components/Landing";
import Blogs from './components/Blogs';
import YogaDetection from "./components/blogs/YogaDetection";
import VideoResume from "./components/blogs/VideoResume";
import DeployFlask from "./components/blogs/DeployFlask";
import Chatbot from "./components/blogs/Chatbot";
import Tableau from "./components/blogs/Tableau";
import Solutions from './components/Solutions';
import Medha from './components/solutions/Medha';
import Namaste from './components/solutions/Namaste';

import Footer from './components/Footer';
import theme from "./components/ui/Theme";
import { ThemeProvider } from "@material-ui/styles";

const App = () => {
  return (
    <Container fluid>
      <Router>
        <NavBar />
        <Switch>
          <Route exact path="/blogs">
            <Blogs />
          </Route>
          <Route exact path="/blogs/yoga">
            <YogaDetection />
          </Route>
          <Route exact path="/blogs/videoResume">
            <VideoResume />
          </Route>
          <Route exact path="/blogs/deployFlask">
            <DeployFlask />
          </Route>
          <Route exact path="/blogs/chatbot">
            <Chatbot />
          </Route>
          <Route exact path="/blogs/tableau">
            <Tableau />
          </Route>
          <Route exact path="/solutions">
            <Solutions />
          </Route>
          <Route exact path="/solutions/medha">
            <Medha />
          </Route>
          <Route exact path="/solutions/namaste">
            <Namaste />
          </Route>
          <Route exact path="/">
            <ThemeProvider theme={theme}>
              <Landing />
            </ThemeProvider>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </Container>
  );
};

const Container = styled.div`
  font-family: "Raleway", sans-serif;
`;

export default App;
