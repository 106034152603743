import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import HeaderLogo from '../../assets/Blogs/Deploy_Flask_App/MediumHeader.gif';
import Img1 from '../../assets/Blogs/Deploy_Flask_App/img1.png';
import Img2 from '../../assets/Blogs/Deploy_Flask_App/img2.png';
import Img3 from '../../assets/Blogs/Deploy_Flask_App/img3.png';
import Img4 from '../../assets/Blogs/Deploy_Flask_App/img4.png';
import Img5 from '../../assets/Blogs/Deploy_Flask_App/img5.png';
import Img6 from '../../assets/Blogs/Deploy_Flask_App/img6.png';
import Img7 from '../../assets/Blogs/Deploy_Flask_App/img7.png';
import Img8 from '../../assets/Blogs/Deploy_Flask_App/img8.png';
import Img9 from '../../assets/Blogs/Deploy_Flask_App/img9.png';
import Img10 from '../../assets/Blogs/Deploy_Flask_App/img10.png';
import Img11 from '../../assets/Blogs/Deploy_Flask_App/img11.png';
import Img12 from '../../assets/Blogs/Deploy_Flask_App/img12.png';
import Img13 from '../../assets/Blogs/Deploy_Flask_App/img13.png';
import Img14 from '../../assets/Blogs/Deploy_Flask_App/img14.png';
import Img15 from '../../assets/Blogs/Deploy_Flask_App/img15.png';
import Img16 from '../../assets/Blogs/Deploy_Flask_App/img16.png';
import Img17 from '../../assets/Blogs/Deploy_Flask_App/img17.png';

import BlogModal from './BlogModals';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em !important',
    }
  },
}));

export default function DeployFlask() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <BlogModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' className={classes.headingText} style={{fontSize: '3.7em', fontWeight: '700', fontFamily: 'monospace'}} gutterBottom>
          Deploying a Flask Application on AWS EC2 Instance for Pose-Estimation
        </Typography>
        <Typography align={'center'} variant='h6' style={{fontWeight: '700', color: '#888', fontFamily: 'monospace'}} gutterBottom>
          Deploy a Flask Web-App on the EC2 Instance of AWS
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          <span style={{paddingRight: '0.5vw'}}>
            Get source code for flask web-app
          </span>
          <a href={'https://github.com/YogeshMaan/PoseEstimation.git'}>
            here
          </a>
          <span style={{paddingRight: '0.3vw'}}>
            .
          </span>
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          1. Creating and Activating a new AWS account
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          <span style={{paddingRight: '0.5vw'}}>
            Please refer to this official step by step guide
          </span>
          <a href={'https://aws.amazon.com/premiumsupport/knowledge-center/create-and-activate-aws-account/'}>
            link
          </a>
          <span style={{paddingRight: '0.3vw'}}>
            .
          </span>
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          2. Launching an EC2 instance
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          STEP 1: Open AWS Management Console & select a zone from one of the available zones according to your location. I live in India so I will be choosing Asia Pacific (Mumbai).
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img1}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          STEP 2: After being redirected to EC2 dashboard, click on “Launch instance” to launch an EC2 instance.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img2}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          STEP 3: Setting up an Instance according to our app requirements: a) Choose AMI — select Ubuntu Server 20.04 LTS (HVM), SSD Volume Type (free tier eligible)
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img3}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          b) Choose Instance type
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img4}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          c) Configure Instance — you can change settings based on your requirements. However, we’ll keep these settings as default.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          d) Add storage — default 8 GB would be sufficient for our Pose-Estimation project. You can extend the storage as per your requirement, as free tier eligible customers can get up to 30 GB of EBS General Purpose (SSD) or Magnetic storage. Learn more about free usage tier eligibility and usage restrictions.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          e) Review and launch — click on the “Review and Launch” button on bottom right of the screen.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          3. Downloading the key pair.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ After clicking on the launch button — a dialog box(as shown below) will appear.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img5}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Select “Create a new key pair” from the drop down menu, give a name to the key pair .pem file and download the file on your local machine.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img6}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          4. Connect to your Linux instance using an SSH client
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Go to EC2 dashboard -> Instances(running) -> select the created instance.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img7}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Click on “Connect” button. A dialog box (as shown below) will appear.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img8}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Open your local machine’s terminal and run the following commands —
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img9}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img10}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img11}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img12}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Run the following commands on the cloud server —
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo apt-get update
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          python3 -V  #ensure that python version is 3.8 or above
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo apt install python3-pip
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo apt-get install nginx
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Clone git repository —
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo apt install git
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          git clone https://github.com/YogeshMaan/PoseEstimation.git
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Install the required dependencies — Navigate to PoseEstimation/ directory
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          cd PoseEstimation
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          pip3 install -r requirements.txt
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          5. Configuring Nginx
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Run the following commands — to navigate to the config files of nginx.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          cd
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          cd /etc/nginx/sites-enabled/
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo vim flaskapp
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Save the above file and restart the nginx service.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          sudo service nginx restart
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Now, navigate to the PoseEstimation directory and run the gunicorn.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          cd
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          cd PoseEstimation
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', backgroundColor: '#ddd', textAlign: 'justify'}} gutterBottom>
          gunicorn app:app
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img13}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          6. Configuring Security groups
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Navigate to the security group of your running instance —
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img14}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Edit inbound Rules —
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img15}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Add rule & save rule —
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img16}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          7. Run your flask app
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          ▹ Open url http://(your Public ip4 address):80
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={Img17}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700', textAlign: 'justify'}} gutterBottom>
          Hope you found this helpful! 😊
        </Typography>
        

        
      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        
      </Grid>      
    </Grid>
  );
}