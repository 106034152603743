import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import caraouselImage from '../assets/carousel.png'


function UpperCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item style={{ height: "80vh" }}>
                <img
                    className="d-block w-100"
                    src={caraouselImage}
                    alt="First slide"
                    style={{ height: "80vh" }}
                />
                <Carousel.Caption style={{ top: '0px', textAlign: 'left', paddingTop: '10vh' }}>
                    <h2 style={{ color: '#000', fontWeight: 'bold' }}>Knowledge is free. Knowledge is absolute power.</h2>
                    <p style={{ color: '#000000', fontSize: '1.4rem', marginTop: '10px' }}>'Kaglorsys is derived from <span style={{ fontWeight: 'bold' }}> Kag </span> (Sanskrit Word Meaning ' act on '), such ' <span style={{ fontWeight: 'bold' }}> LORE </span> (English Word Meaning Knowledge) <span style={{ fontWeight: 'bold' }}> Sys </span> tems.</p>
                    <p style={{ color: '#000000', fontSize: '1.4rem' }}>Kaglorsys offers solutions in building systems that get insights embedded deep inside available knowledge database. These systems provide visualization that is not clearly discernible to Human intelligence but when complemented by Artificial Intelligence expertise of our company let decision makers take actions for safer, healthier, wealthier, greener planet earth.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{ height: "80vh", textAlign: 'left' }}>
                <img
                    className="d-block w-100"
                    src={caraouselImage}
                    alt="Second slide"
                    style={{ height: "80vh" }}
                />
                <Carousel.Caption style={{ textAlign: 'left', top: '0px', paddingTop: '15vh' }}>
                    <h2 style={{ color: '#000', fontWeight: 'bold' }}>Kaglorsys is an AI R&D,
                        Product Development and Consulting Firm</h2>
                    <p style={{ color: '#000', fontWeight: 'normal', fontSize: '1.4rem', lineHeight: '35px', marginTop: '20px' }}>Using AI consulting services, discover new chances to expand your product and improve business outcomes with personalized solutions.
                    </p>
                    <Button href="/#contacts" style={{ backgroundColor: '#D8841A', padding: '10px 30px 10px 30px' }} variant="primary" size="lg" active>
                        Contact
                    </Button>{' '}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}


export default UpperCarousel;
