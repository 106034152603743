import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from 'react-router-dom';

import medha from '../assets/Solutions/Medha.png';
import namaste from '../assets/Solutions/Namaste.png';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  lorem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  linkText: {
    color: '#696969',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  },
}));

export default function Solutions() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column' style={{paddingBottom: '5vh'}}>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' style={{fontWeight: '700', fontSize: '4rem',fontFamily: 'monospace'}}>
          Solutions
        </Typography>
        <Typography align={'center'} variant='h6' className={classes.lorem} style={{fontWeight: '300',fontSize: '0.5em', color: '#fff', fontFamily: 'monospace'}}>
          On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-device Machine Learning using Deep Learning Neural Networks in TensorFlow and Keras (Step By Step Process) On-
        </Typography>
      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        <img
          src={medha}
          alt="startup img"
          style={{
            width: matchesSM ? 60 : '6em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/solutions/medha">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '900', color: 'hsl(242, 50%, 48%)'}} gutterBottom>
              Medha
            </Typography>
            <Typography variant='h6' className={classes.linkText} style={{fontWeight: '500', fontFamily: 'monospace'}} gutterBottom>
              AI for Recruiting
            </Typography>
          </Grid>
        </Link>
      </Grid>

      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex', paddingBottom: '15vh'}}>
        <img
          src={namaste}
          alt="startup img"
          style={{
            width: matchesSM ? 60 : '6em',
            height: matchesSM ? 60 : '6em',
            marginRight: matchesMD ? 15 : '5em',
            marginBottom: matchesMD ? '5em' : 0,
          }}
        />
        <Link to="/solutions/namaste">
          <Grid>
            <Typography variant='h5' className={classes.linkText} style={{fontWeight: '900', color: 'hsl(242, 50%, 48%)'}} gutterBottom>
              Namaste
            </Typography>
            <Typography variant='h6' className={classes.linkText} style={{fontWeight: '500', fontFamily: 'monospace'}} gutterBottom>
              AI Voice Based Personal Assistant
            </Typography>
          </Grid>
        </Link>
      </Grid>
    
    </Grid>
  );
}
