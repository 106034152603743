import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import "../theme/styles.css";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MultiSelect from "react-multi-select-component";
import ReCaptchaV2 from "react-google-recaptcha";
import emailjs from "emailjs-com";

import background from "../assets/bg.png";
// import startup from "../assets/Startup_PNG.png";
import aboutbg from "../assets/aboutbg.png";
// import vision from "../assets/Vision.png";
import my_vision from "../assets/vision.png";
import mission from "../assets/mission.png";
import team1 from "../assets/Seshu.png";
import team2 from "../assets/Pravin.png";
import team3 from "../assets/Prabhanjan.png";
import tech2 from "../assets/TechStack/2.png";
import tech3 from "../assets/TechStack/3.png";
import tech4 from "../assets/TechStack/4.png";
import tech5 from "../assets/TechStack/5.png";
import tech6 from "../assets/TechStack/6.png";
import tech7 from "../assets/TechStack/7.png";
import tech8 from "../assets/TechStack/8.png";
import tech9 from "../assets/TechStack/9.png";
import tech10 from "../assets/TechStack/10.png";
import tech11 from "../assets/TechStack/11.png";
import tech12 from "../assets/TechStack/12.png";
import tech13 from "../assets/TechStack/13.png";
import partner1 from "../assets/Patners/patner1.jpeg";
import partner2 from "../assets/Patners/patner2.jpeg";
import partner3 from "../assets/Patners/patner3.jpeg";

import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TranslateIcon from "@material-ui/icons/Translate";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BarChartIcon from '@material-ui/icons/BarChart';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
// import CallIcon from "@material-ui/icons/Call";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
// import EmailIcon from "@material-ui/icons/Email";
// import caraouselImage from "../assets/carousel.png";
import UpperCarousel from "./Carousel";

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  mainRowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      height: "120vh",
    },
  },
  aboutSection: {
    backgroundImage: `url(${aboutbg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      // height: '200vh'
    },
  },
  visionSection: {
    backgroundColor: "#fff7ef",
    [theme.breakpoints.down("sm")]: {
      // height: '200vh'
    },
  },
  servicesSection: {
    background: "#b3e5e8",
  },
  ourTeam: {
    // [theme.breakpoints.down('md')]: {
    //   height: '250vh'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   height: '300vh'
    // }
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#65C9DF",
    "&:hover": {
      backgroundColor: "#00457D",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  root: {
    backgroundColor: "#fff7ef",
    boxShadow: "none",
    // "&:hover": {
    //   backgroundColor: "#2E2F54",
    //   curser: "pointer",
    // },
  },
  card: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#daf2f4",
      curser: "pointer",
      zDepth: "1",
    }
  },
  bottomForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "70%",
    "& > *": {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      width: "120%",
      maxWidth: "120%",
    },
  },
  multiSelect: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 50,
    width: 150,
    fontSize: "1rem",
    color: "#1D1C30",
    backgroundColor: "#FFFFFF",
    border: `1px solid`,
    borderColor: "#1D1C30",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#1D1C30",
    },
  },
  socialMediaButtons: {
    width: "17vw",
    [theme.breakpoints.down("md")]: {
      width: "26vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      width: "90vw",
    },
  },
}));

export default function Landing() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phNo, setPhNo] = useState();
  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  var test = JSON.stringify(selectedContactOptions);

  const defaultForm = {
    token: "",
  };
  const [form, setForm] = React.useState(defaultForm);

  const multiContactOptions = [
    {
      label: "AI Driven Mobile application",
      value: "AI Driven Mobile application",
    },
    { label: "AI Driven Web application", value: "AI Driven Web application" },
    {
      label: "AI & Computer Vision development",
      value: "AI & Computer Vision development",
    },
    { label: "Technical support", value: "Technical support" },
  ];

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lktqepk",
        "template_bfpayls",
        e.target,
        "user_1t5wsboBXm40mARhGIttn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setPhNo("");
    setSelectedContactOptions("");
    alert(`Thanks for reaching out, we will connect with you shortly!`);
  }

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token };
    });
  };

  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: null };
    });
  };

  return (
    <>
      <UpperCarousel />
      <Grid container direction="column">
        {/* <Grid item className={classes.mainRowContainer} style={{paddingTop: '3em', width: 'auto !important'}}>
        <Grid
          item
          container
          direction={matchesMD ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
          style={{height: '84vh'}}
        >
          <Grid item container direction='column' lg style={{maxWidth: '40em'}}>
            <Grid item>
              <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '3em'}} variant='h2' gutterBottom>
                Kaglorsys is an AI R&D,<br />Product Development and Consulting Firm
              </Typography>
              <Typography align={matchesMD ? 'center' : 'left'} style={{color: '#00FFFF', fontSize: '1.7em', textAlign: 'justify'}} variant='body1' paragraph>
                Help in viewing, mining, presenting and acting on knowledge systems world using Artificial Intelligence, Machine Learning, Data Science, Analytics, IoT.
              </Typography>
              <Grid align={matchesMD ? 'center' : 'left'}>
                <Grid className={classes.socialMediaButtons} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3vh', paddingBottom: '4vh'}}>
                  <a href={'https://www.facebook.com/Kaglorsys-Technologies-103631822017090'}>
                    <Grid item>
                      <FacebookIcon style={{fontSize: "60", color: '#1873EB', padding: '7px'}} />
                    </Grid>
                  </a>
                  <a href={'https://www.instagram.com/kaglorsys/'}>
                    <Grid item>
                      <InstagramIcon style={{fontSize: "60", color: '#E10927', padding: '7px'}} />
                    </Grid>
                  </a>
                  <a href={'https://twitter.com/kaglorsys'}>
                    <Grid item>
                      <TwitterIcon style={{fontSize: "60", color: '#009DED', padding: '7px'}} />
                    </Grid>
                  </a>
                  <a href={'https://www.linkedin.com/company/71490194/admin/'}>
                    <Grid item>
                      <LinkedInIcon style={{fontSize: "60", color: '#1873EB', padding: '7px'}} />
                    </Grid>
                  </a>
                  <a href={'mailto:sk@kaglorsys.com'}>
                    <Grid item>
                      <EmailIcon style={{fontSize: "60", color: '#d94133', padding: '7px'}} />
                    </Grid>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

        {/* Services section starts here  */}
        <Grid style={{ backgroundColor: "#fff7ef" }} id="services">
          <Grid item style={{ paddingBottom: "8vh" }}>
            <Typography
              align="left"
              variant="h2"
              style={{ padding: "30px 0px 0px 6vw", color: "#000000" }}
            >
              Services
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.root}
                style={{
                  alignItems: "center",
                  width: "280px",
                  height: "340px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <AccountTreeIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                      // color:
                      // paddingLeft: "10px",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      AI Consulting
                    </Typography>
                  </CardContent>
                </Grid>
                <CardContent
                  style={{ padding: "10px 8px 10px 8px", maxWidth: "420px" }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                    }}
                    paragraph
                  >
                    Do you have an idea and need assistance putting it into
                    action? Our data scientists are available to consult with
                    you and share their expertise.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.root}
                style={{
                  alignItems: "center",
                  width: "280px",
                  height: "340px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <AcUnitIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                      // color:
                      // paddingLeft: "10px",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      POC of an AI Based Solution
                    </Typography>
                  </CardContent>
                </Grid>
                <CardContent
                  style={{ padding: "10px 8px 10px 8px", maxWidth: "420px" }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                    }}
                    paragraph
                  >
                    Our data science experts will validate and construct a proof
                    of concept for you if you have a project idea in mind.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.root}
                style={{
                  alignItems: "center",
                  width: "280px",
                  height: "340px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <StarOutlineIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                      // color:
                      // paddingLeft: "10px",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      MVP of an AI Based Product
                    </Typography>
                  </CardContent>
                </Grid>
                <CardContent
                  style={{ padding: "10px 8px 10px 8px", maxWidth: "420px" }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                    }}
                    paragraph
                  >
                    If you need a game-changing AI product, we'll create an MVP
                    with all of the necessary features.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.root}
                style={{
                  alignItems: "center",
                  width: "280px",
                  height: "340px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <DesktopWindowsIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      AI-Driven Mobile/Web App Developement
                    </Typography>
                  </CardContent>
                </Grid>
                <CardContent
                  style={{ padding: "10px 8px 10px 8px", maxWidth: "420px" }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                    }}
                    paragraph
                  >
                    The market for online and mobile apps is always evolving.
                    We'll integrate AI capabilities into your mobile or online
                    apps.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.root}
                style={{
                  alignItems: "center",
                  width: "280px",
                  height: "340px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <PhoneAndroidIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                      // color:
                      // paddingLeft: "10px",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      AI&ML Empowering of your Mobile/Web App
                    </Typography>
                  </CardContent>
                </Grid>
                <CardContent
                  style={{ padding: "10px 8px 10px 8px", maxWidth: "420px" }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                    }}
                    paragraph
                  >
                    We're here to help you create a cutting-edge web or mobile
                    app from the ground up, complete with AI capabilities.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Services section end here */}
        {/* Our partners section starts here */}
        <Grid style={{ background: "#B3E5E8", paddingBottom: "12vh" }}>
          <Grid item style={{ paddingBottom: "8vh" }}>
            <Typography
              align="left"
              variant="h2"
              style={{ padding: "30px 0px 0px 6vw", color: "#000000" }}
            >
              Our Partners
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              paddingTop: "10vh",
              paddingBottom: "10vh",
            }}
          >
            <Grid item lg>
              <img
                src={partner1}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "18em",
                  height: matchesSM ? 100 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
            <Grid item lg>
              <img
                src={partner2}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "18em",
                  height: matchesSM ? 100 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
            <Grid item lg>
              <img
                src={partner3}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "18em",
                  height: matchesSM ? 100 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Our partners section end here */}
        {/* <Grid
          item
          style={{ paddingTop: "3em" }}
          className={classes.aboutSection}
        >
          <Grid item>
            <Typography
              align="center"
              variant="h2"
              gutterBottom
              style={{ color: "#ffffff" }}
            >
              ABOUT US
            </Typography>
          </Grid>
          <Grid item className={classes.rowContainer}>
            <Grid
              item
              container
              direction={matchesMD ? "column" : "row"}
              alignItems="center"
              className={classes.rowContainer}
            >
              <Grid
                item
                container
                direction="column"
                lg
                style={{ maxWidth: "40em" }}
              >
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : "left"}
                    style={{
                      fontSize: "2em",
                      color: "#fff",
                      textAlign: "justify",
                    }}
                    variant="body1"
                    paragraph
                  >
                    We believe in building and maintaining long term
                    relationships with all our clients.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : "left"}
                    style={{
                      fontSize: "2em",
                      color: "#fff",
                      textAlign: "justify",
                    }}
                    variant="body1"
                    paragraph
                  >
                    We provide world class, high quality, affordable services to
                    our clients.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : "left"}
                    style={{
                      fontSize: "2em",
                      color: "#fff",
                      textAlign: "justify",
                    }}
                    variant="body1"
                    paragraph
                  >
                    Our team has rich experience in AI, Data Science, Analytics
                    and IoT.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg align={matchesMD ? "center" : "right"}>
                <img
                  src={startup}
                  alt="startup img"
                  style={{
                    maxWidth: matchesSM ? 280 : "22.5em",
                    marginRight: matchesMD ? 0 : "5em",
                    paddingTop: matchesMD ? "3em" : 0,
                    marginBottom: matchesMD ? "5em" : 0,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid
          item
          style={{ paddingTop: "3em" }}
          className={classes.visionSection}
        >
          <Grid item>
            <Typography
              align="left"
              variant="h2"
              gutterBottom
              style={{ padding: "30px 0px 0px 6vw", color: "#000" }}
            >
              Vision and Mission
            </Typography>
          </Grid>
          <Grid item className={classes.rowContainer}>
            <Grid
              item
              container
              direction={matchesMD ? "column" : "row"}
              alignItems="center"
              className={classes.rowContainer}
            >
              {/* <img
                className="d-block w-100"
                src={caraouselImage}
                alt="First slide"
                style={{ height: "80vh" }}
              /> */}
              <Grid item lg align={matchesMD ? "center" : "left"}>
                <img
                  src={my_vision}
                  alt="startup img"
                  style={{
                    maxWidth: matchesSM ? 280 : "18em",
                    marginRight: matchesMD ? 0 : "5em",
                    paddingTop: matchesMD ? "3em" : 0,
                    marginBottom: matchesMD ? "5em" : 0,
                  }}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                lg
                style={{ maxWidth: "40em" }}
              >
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : "left"}
                    style={{
                      fontSize: "1.4em",
                      color: "#000",
                      textAlign: "justify",
                    }}
                    variant="body1"
                    paragraph
                  >
                    <h2
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        paddingBottom: "15px",
                      }}
                    >
                      Vision
                    </h2>
                    Acting on knowledge systems using human intelligence
                    empowered by artificial intelligence leading to decisive
                    actions for a safer, wealthier, healthier and greener
                    planet.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ paddingTop: "3em" }}
          className={classes.visionSection}
        >
          <Grid item className={classes.rowContainer}>
            <Grid
              item
              container
              direction={matchesMD ? "column" : "row"}
              alignItems="center"
              className={classes.rowContainer}
            >
              <Grid
                item
                container
                direction="column"
                lg
                style={{ maxWidth: "40em" }}
              >
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : "left"}
                    style={{
                      fontSize: "1.4em",
                      color: "#000",
                      textAlign: "justify",
                      flexWrap: "wrap-reverse",
                    }}
                    variant="body1"
                    paragraph
                  >
                    <h2
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        paddingBottom: "15px",
                      }}
                    >
                      Mission
                    </h2>
                    To help organizations increase ROI by incorporating AI, ML,
                    Analytics in their technology roadmap and unlock the next
                    level of innovation and growth.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg align={matchesMD ? "center" : "right"}>
                <img
                  src={mission}
                  alt="startup img"
                  style={{
                    maxWidth: matchesSM ? 280 : "18em",
                    marginRight: matchesMD ? 0 : "4em",
                    paddingTop: matchesMD ? "3em" : 0,
                    marginBottom: matchesMD ? "5em" : 0,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.servicesSection} id="expertise">
          <Grid item style={{ paddingBottom: "12vh" }}>
            <Typography
              align="left"
              variant="h2"
              style={{ padding: "30px 0px 0px 6vw", color: "#000" }}
            >
              Our Expertise
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                    backgroundColor: "#b3e5e8",
                  }}
                >
                  <DesktopWindowsIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      Computer Vision
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                    backgroundColor: "#b3e5e8",
                  }}
                >
                  <TranslateIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                     Natural Language Processing
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                    backgroundColor: "#b3e5e8",
                  }}
                >
                  <VisibilityIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                      Advanced Analytics
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    paddingLeft: "23px",
                    paddingTop: "10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignContent: "space-around",
                    alignItems: "center",
                    backgroundColor: "#b3e5e8",
                  }}
                >
                  <BarChartIcon
                    style={{
                      fontSize: "80",
                      color: "#68b44e",
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 0px 0px 0px",
                      maxWidth: "350px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                      }}
                      paragraph
                    >
                     Data Capture and OCR
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ background: "#fff" }}>
          <Grid item style={{ paddingBottom: "8vh" }}>
            <Typography
              align="center"
              variant="h2"
              style={{ padding: "30px 0px 0px 0px", color: "#000000" }}
            >
              Technology Stack
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  width: "300px",
                  height: "230px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 8px 0px 30px",
                    maxWidth: "350px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    paragraph
                  >
                    AI/ML/DL
                  </Typography>
                </CardContent>
                <CardContent
                  style={{ padding: "10px 8px 0px 40px", maxWidth: "420px" }}
                >
                  <img
                    src={tech8}
                    alt="img"
                    style={{
                      width: matchesSM ? 130 : "7em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                  <img
                    src={tech9}
                    alt="img"
                    style={{
                      width: matchesSM ? 170 : "9em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                  <img
                    src={tech7}
                    alt="img"
                    style={{
                      width: matchesSM ? 140 : "7em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? 0 : "1em",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  width: "300px",
                  height: "230px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 8px 0px 30px",
                    maxWidth: "350px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    paragraph
                  >
                    Full Stack
                  </Typography>
                </CardContent>
                <CardContent
                  style={{ padding: "0px 8px 0px 40px", maxWidth: "420px" }}
                >
                  <img
                    src={tech3}
                    alt="img"
                    style={{
                      width: matchesSM ? 140 : "7em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                  <img
                    src={tech4}
                    alt="img"
                    style={{
                      width: matchesSM ? 120 : "5.5em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                  <img
                    src={tech6}
                    alt="img"
                    style={{
                      width: matchesSM ? 140 : "7em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  width: "300px",
                  height: "230px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 8px 0px 30px",
                    maxWidth: "350px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    paragraph
                  >
                    Data Engineering
                  </Typography>
                </CardContent>
                <CardContent
                  style={{ padding: "10px 8px 0px 40px", maxWidth: "420px" }}
                >
                  <img
                    src={tech2}
                    alt="img"
                    style={{
                      width: matchesSM ? 90 : "4em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "0.3em" : "1em",
                    }}
                  />
                  <br />
                  <img
                    src={tech5}
                    alt="img"
                    style={{
                      width: matchesSM ? 90 : "4em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1.5em",
                    }}
                  />
                  <br />
                  <img
                    src={tech13}
                    alt="img"
                    style={{
                      width: matchesSM ? 130 : "8em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "1em",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ paddingBottom: "7vh" }}>
              <Card
                className={classes.card}
                style={{
                  alignItems: "center",
                  width: "300px",
                  height: "230px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 8px 0px 30px",
                    maxWidth: "350px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    paragraph
                  >
                    Cloud
                  </Typography>
                </CardContent>
                <CardContent
                  style={{ padding: "10px 8px 0px 40px", maxWidth: "420px" }}
                >
                  <img
                    src={tech10}
                    alt="img"
                    style={{
                      width: matchesSM ? 65 : "4em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "0.5em",
                    }}
                  />
                  <br />
                  <img
                    src={tech11}
                    alt="img"
                    style={{
                      width: matchesSM ? 100 : "7em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : "0.5em",
                    }}
                  />
                  <br />
                  <img
                    src={tech12}
                    alt="img"
                    style={{
                      width: matchesSM ? 150 : "11em",
                      marginRight: matchesMD ? 0 : "5em",
                      marginBottom: matchesMD ? "1em" : 0,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ backgroundColor: "#FFF7EF" }}
          className={classes.ourTeam}
        >
          <Grid item style={{ paddingBottom: "8vh" }}>
            <Typography
              align="center"
              variant="h2"
              style={{ padding: "30px 0 0 0", color: "#000000" }}
            >
              Our Team
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ paddingTop: "4vh", paddingBottom: "4vh" }}
          >
            <Grid
              item
              container
              direction="column"
              lg
              style={{ maxWidth: "40em" }}
            >
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h2"
                  gutterBottom
                >
                  Seshu Kunapuli
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="body1"
                  paragraph
                >
                  Founder & CEO
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h4"
                  gutterBottom
                >
                  Business Development & Customer Engagement
                  <br />
                  <br />
                  IoT, Embedded Systems, Data Networking
                  <br />
                  <br />
                  Bachelors in Electrical Engineering from BITS, Pilani
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg align={matchesMD ? "center" : "right"}>
              <img
                src={team1}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "25em",
                  marginRight: matchesMD ? 0 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ paddingTop: "4vh", paddingBottom: "4vh" }}
          >
            <Grid item lg align={matchesMD ? "center" : "left"}>
              <img
                src={team2}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "25em",
                  marginRight: matchesMD ? 0 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              lg
              style={{ maxWidth: "40em" }}
            >
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h2"
                  gutterBottom
                >
                  Pravin Karandikar
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="body1"
                  paragraph
                >
                  CTO
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h4"
                  gutterBottom
                >
                  Product Delivery, Program Management
                  <br />
                  <br />
                  Embedded Systems, DSP, ML, Video Analytics, Blockchain
                  <br />
                  <br />
                  Masters in Communications from IIT Mumbai
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            alignItems="center"
            className={classes.rowContainer}
            style={{ paddingTop: "4vh", paddingBottom: "4vh" }}
          >
            <Grid
              item
              container
              direction="column"
              lg
              style={{ maxWidth: "40em" }}
            >
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h2"
                  gutterBottom
                >
                  Prabhanjan Kavuri
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="body1"
                  paragraph
                >
                  Head of Strategy
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={"center"}
                  style={{ color: "#000000" }}
                  variant="h4"
                  gutterBottom
                >
                  Marketing Strategy
                  <br />
                  <br />
                  AWS Cloud Services
                  <br />
                  <br />
                  MBA from Rutgers University
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg align={matchesMD ? "center" : "right"}>
              <img
                src={team3}
                alt="startup img"
                style={{
                  width: matchesSM ? 300 : "25em",
                  marginRight: matchesMD ? 0 : "5em",
                  paddingTop: matchesMD ? "2em" : 0,
                  marginBottom: matchesMD ? "5em" : 0,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ backgroundColor: "#ffffff" }} id="contacts">
          <Grid item style={{ paddingBottom: "8vh" }}>
            <Typography
              align="center"
              variant="h2"
              style={{ padding: "30px 0px 0px 0px", color: "#000" }}
            >
              How Can We Help You
            </Typography>
          </Grid>
          <Grid
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "20px",
            }}
          >
            <Grid
              item
              container
              direction={matchesSM ? "column" : "row"}
              alignItems="center"
              className={classes.lastRowContainer}
            >
              <Grid item container direction="column" lg>
                <Grid
                  item
                  lg
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid item style={{ display: "flex", padding: "5px" }}>
                      <Typography
                        align={matchesMD ? "center" : "left"}
                        style={{
                          fontSize: "1.7rem",
                          color: "#E74C3C",
                          fontWeight: "900",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body1"
                        paragraph
                      >
                        SAN FRANCISCO
                      </Typography>
                    </Grid>
                    <Grid item style={{ display: "flex", padding: "5px" }}>
                      <LocationOnIcon
                        style={{
                          fontSize: "40",
                          color: "#1500FF",
                          padding: "7px",
                        }}
                      />
                      <Typography
                        align={matchesMD ? "center" : "left"}
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          fontWeight: "700",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body1"
                        paragraph
                      >
                        25700 Stoneridge Mall Road<br />
                        Suite 285<br />
                        Pleasanton, CA 94588
                      </Typography>
                    </Grid>
                    {/* <Grid
                      item
                      style={{
                        display: "flex",
                        padding: "5px",
                        paddingBottom: "5vh",
                      }}
                    >
                      <CallIcon
                        style={{
                          fontSize: "40",
                          color: "#1500FF",
                          padding: "7px",
                        }}
                      />
                      <Typography
                        align={matchesMD ? "center" : "left"}
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          fontWeight: "700",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body1"
                        paragraph
                      >
                        +1 (415) 997 3219
                      </Typography>
                    </Grid> */}
                    {/* <Grid item style={{display: 'flex', padding: '5px'}}>
                    <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1.7rem', color: '#E74C3C', fontWeight: '900', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                      NEW JERSEY
                    </Typography>
                  </Grid> */}
                    {/* <Grid item style={{display: 'flex', padding: '5px'}}>
                    <LocationOnIcon style={{fontSize: "40", color: '#1500FF', padding: '7px'}} />
                    <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1.2rem', color: 'black', fontWeight: '700', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                      1 Daffodil Drive, Edison, NJ-0883
                    </Typography>
                  </Grid> */}
                    {/* <Grid item style={{display: 'flex', padding: '5px', paddingBottom: '5vh'}}>
                    <CallIcon style={{fontSize: "40", color: '#1500FF', padding: '7px'}} />
                    <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1.2rem', color: 'black', fontWeight: '700', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                      +1 (214)-850-5603
                    </Typography>
                  </Grid> */}
                    <Grid item style={{ display: "flex", padding: "5px" }}>
                      <Typography
                        align={matchesMD ? "center" : "left"}
                        style={{
                          fontSize: "1.7rem",
                          color: "#E74C3C",
                          fontWeight: "900",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body1"
                        paragraph
                      >
                        HYDERABAD
                      </Typography>
                    </Grid>
                    <Grid item style={{ display: "flex", padding: "5px" }}>
                      <LocationOnIcon
                        style={{
                          fontSize: "40",
                          color: "#1500FF",
                          padding: "7px",
                        }}
                      />
                      <Typography
                        align={matchesMD ? "center" : "left"}
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          fontWeight: "700",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body1"
                        paragraph
                      >
                        Sy 993, 45, Golden Palms Colony
                        <br />
                        Hyderabad, Telangana, 502032
                      </Typography>
                    </Grid>
                    <a href={"mailto:sk@kaglorsys.com"}>
                      <Grid item style={{ display: "flex", padding: "5px" }}>
                        <MailOutlineIcon
                          style={{
                            fontSize: "40",
                            color: "#1500FF",
                            padding: "7px",
                          }}
                        />
                        <Typography
                          align={matchesMD ? "center" : "left"}
                          style={{
                            fontSize: "1.2rem",
                            color: "black",
                            fontWeight: "700",
                            margin: "0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          variant="body1"
                          paragraph
                        >
                          sk@kaglorsys.com
                        </Typography>
                      </Grid>
                    </a>
                    <Grid
                      className={classes.socialMediaButtons}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: "3vh",
                        paddingBottom: "4vh",
                      }}
                    >
                      <a
                        href={
                          "https://www.facebook.com/Kaglorsys-Technologies-103631822017090"
                        }
                      >
                        <Grid item>
                          <FacebookIcon
                            style={{
                              fontSize: "60",
                              color: "#1873EB",
                              padding: "7px",
                            }}
                          />
                        </Grid>
                      </a>
                      <a href={"https://www.instagram.com/kaglorsys/"}>
                        <Grid item>
                          <InstagramIcon
                            style={{
                              fontSize: "60",
                              color: "#E10927",
                              padding: "7px",
                            }}
                          />
                        </Grid>
                      </a>
                      <a href={"https://twitter.com/kaglorsys"}>
                        <Grid item>
                          <TwitterIcon
                            style={{
                              fontSize: "60",
                              color: "#009DED",
                              padding: "7px",
                            }}
                          />
                        </Grid>
                      </a>
                      <a
                        href={
                          "https://www.linkedin.com/company/71490194/admin/"
                        }
                      >
                        <Grid item>
                          <LinkedInIcon
                            style={{
                              fontSize: "60",
                              color: "#0A66C2",
                              padding: "7px",
                            }}
                          />
                        </Grid>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg>
                <Grid
                  item
                  lg
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <form
                    className={classes.bottomForm}
                    noValidate
                    autoComplete="off"
                    gutterBottom
                    onSubmit={sendEmail}
                  >
                    <TextField
                      error={
                        !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(name)
                      }
                      label="Name"
                      id="outlined-basic"
                      value={name}
                      name="name"
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                      error={
                        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          email
                        )
                      }
                      id="outlined-basic"
                      label="Email"
                      name="email"
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <PhoneInput
                      style={{ height: "10%", width: "100%" }}
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="IN"
                      value={phNo}
                      onChange={setPhNo}
                      name="phNo"
                    />
                    <TextField
                      error={subject.length < 7}
                      id="outlined-basic"
                      label="Subject"
                      name="subject"
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={(event) => setSubject(event.target.value)}
                    />
                    <TextField
                      error={message.length < 15}
                      id="outlined-basic"
                      label="Message"
                      name="message"
                      variant="outlined"
                      multiline
                      rows={5}
                      style={{ width: "100%" }}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                    <div className={classes.multiSelect}>
                      <MultiSelect
                        options={multiContactOptions}
                        value={selectedContactOptions}
                        onChange={setSelectedContactOptions}
                        labelledBy="Select"
                        name="selectedContactOptions"
                      />
                    </div>
                    <ReCaptchaV2
                      style={{ padding: "1rem 0" }}
                      sitekey="6Ldo5ewbAAAAAHKdrZuDf4VqI_GxWIAQLNJqFarw"
                      onChange={handleToken}
                      onExpire={handleExpire}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Messege"
                      name="selectedContactOptions"
                      value={test}
                      variant="outlined"
                      style={{ width: "80%", display: "none" }}
                    />
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        disabled={
                          name.length === 0 ||
                          email.length === 0 ||
                          subject.length === 0 ||
                          phNo.length < 13 ||
                          message.length === 0
                        }
                        variant="contained"
                        className={classes.sendButton}
                        align={matchesMD ? "center" : "left"}
                        gutterBottom
                        type="submit"
                      >
                        SUBMIT
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
