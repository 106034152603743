import React from 'react';
import Modal from 'react-modal';
import { useState } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';


import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import emailjs from 'emailjs-com';

const customStyles = {
  content: {
    height: '100vh',
    width: '100vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const useStyles = makeStyles(theme => ({
  modalContainer: {
  },
  bottomForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
      maxWidth: '120%'
    },
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 50,
    width: 150,
    fontSize: '1rem',
    color: "#1D1C30",
    backgroundColor: "#FFFFFF",
    border: `1px solid`,
    borderColor: "#1D1C30",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#1D1C30",
    }
  },
}));

  
export default function SolutionModals() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [phNo, setPhNo] = useState();
  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  // var test = JSON.stringify(selectedContactOptions);

  const defaultForm = {
    token: '',
  }
  const [form, setForm] = React.useState(defaultForm);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_lktqepk', 'template_hv3ptwo', e.target, 'user_1t5wsboBXm40mARhGIttn')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      setPhNo('');
      setSelectedContactOptions('');
      setIsOpen(false);
      alert(`Thanks for giving your information.`);
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#000';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Grid classname={classes.modalContainer}>
          <Typography variant='h5' style={{fontWeight: '500'}} gutterBottom>
            Please fill out these details to get access to the solution
          </Typography>
          <form className={classes.bottomForm} noValidate autoComplete="off" gutterBottom onSubmit={sendEmail}>
            <TextField
              error={!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(name)}
              label="Name"
              id="outlined-basic"
              value={name}
              name="name"
              variant="outlined"
              style={{width: '100%'}}
              onChange={(event) => setName(event.target.value)} 
            />
            <TextField
              error={!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)}
              id="outlined-basic"
              label="Email"
              name="email"
              variant="outlined"
              style={{width: '100%'}}
              onChange={(event) => setEmail(event.target.value)}
            />
            <PhoneInput
              style={{height: '10%', width: '100%'}}
              international
              countryCallingCodeEditable={false}
              defaultCountry="IN"
              value={phNo}
              onChange={setPhNo}
              name="phNo"
            />
            <TextField
              error={company.length < 3}
              id="outlined-basic"
              label="Company"
              name="company"
              variant="outlined"
              style={{width: '100%'}}
              onChange={(event) => setCompany(event.target.value)}
            />
            <Grid item className={classes.buttonContainer}>
              <Button
                disabled={
                  name.length === 0 ||
                  email.length === 0 ||
                  // phNo.length < 13 ||
                  company.length === 0
                }
                variant='contained'
                className={classes.sendButton}
                align={matchesMD ? 'center' : 'left'}
                gutterBottom
                type="submit"
              >
                SUBMIT
              </Button>
            </Grid>
          </form>
        </Grid>
      </Modal>
    </div>
  );
}
