import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import HeaderLogo from '../../assets/Blogs/Tableau/img1.png';

import BlogModal from './BlogModals';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em !important',
    }
  },
}));

export default function Tableau() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <BlogModal />
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em'}}>
        <Typography align={'center'} variant='h2' className={classes.headingText} style={{fontSize: '3.7em', fontWeight: '700', fontFamily: 'monospace'}} gutterBottom>
          Prediction using Tableau with Predictive Modeling Functions
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Understanding the Different Types of Predictive Models in Tableau
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Three different types of regressions are supported by predictive modeling functions: Linear Regression, Regularized Linear Regression, and Gaussian Process Regression. Each model has its use cases, prediction types, and limitations.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Companies are eager to make predictions that don't rely on a time axis and identify outliers, and to use their predictions in additional calculations and export them use in other data files.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          What do you mean by predictive Modeling?
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Predictive modeling functions is a  powerful statistical modeling tools for the analyst working with tableau, enabling us to quickly build and update predictive models. Than these models can be used to understand the relationships with the data with respect to time and also predicting the future estimates. Predictive modeling functions give us the full flexibility to select our own predictors, use the model results within other table calculations, and export our predictions.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          How to use predictive modeling functions?
        </Typography>
        <Typography variant='h6' style={{paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Examples-
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          ● Trend Lines Model
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Trend lines are best fit that are used to predict the continuation of a trend and help to identify the correlation between two variables by mapping the trend of both at the same time.  
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          In this linear trend model is computed for sum of Sales (actual & forecast) given Order Date Quarter. The model may be significant at p less than or eqal to 0.05. The factor Region may be significant at p less than or eqal to 0.05
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          We have predicted the sales of different areas of a supermarket using the trend lines model.
        </Typography>
        <Grid style={{display: 'flex', justifyContent: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
          <img
            src={HeaderLogo}
            alt="startup img"
            style={{
              width: matchesSM ? 350 : '60em',
              marginBottom: matchesMD ? '4vh' : 0,
            }}
          />
        </Grid>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          We have predicted the sales of different areas of a supermarket using the trend lines model.
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ●	Average With 95% CI 
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ● Average with 90% CI
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ● Trend Line
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ● Forecast
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh'}} gutterBottom>
          ● Cluster
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Understanding the Different Types of Predictive Models in Tableau
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          Three different types of regressions are supported by predictive modeling functions: Linear Regression, Regularized Linear Regression, and Gaussian Process Regression. Each model has its use cases, prediction types, and limitations.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          1) Linear Regression
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          This regression is also called Least Squares Regression or OLS. Linear Regression can be used in scenarios where there is at least one predictor. In case there are multiple predictors, they must have a linear relationship between the prediction and prediction target, they should not be dependent on the same underlying conditions and they should not represent different instances of the same data (ex. Temperature should not contain data in Celsius and Fahrenheit).
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          In Tableau, Linear Regression is set as the default model for Predictive Modeling functions unless the model is specified. example.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          2) Regularized Linear Regression
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          This model shows some leniency when compared to the Linear Regression model. We can use this model even if there is no linear relationship between the variables but the catch is that it should almost be a linear relationship. This model is more applicable in the real world. To use this model in Tableau.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          3) Gaussian Process Regression
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          This model is largely used when there is a non-linear relationship between the prediction target and variable(ex: time and space). To use the Gaussian Process Regression in Tableau, we must have at least one ordered dimension for the predictor, however, it may contain one or more unordered predictors.
        </Typography>
        <Typography variant='h5' style={{paddingBottom: '1vh', fontWeight: '700'}} gutterBottom>
          Conclusion
        </Typography>
        <Typography variant='h6' style={{fontFamily: 'monospace', paddingBottom: '2vh', textAlign: 'justify'}} gutterBottom>
          In this, we learned about Tableau forecasting  features of Tableau, the different types of Predictive Modeling functions for Tableau Predictive Analysis. Analyzing real-time data in Tableau is challenging because a lot of its data sources don’t support real-time data transfer. But in tableau this is done very easily.
        </Typography>
        
      </Grid>
      <Grid item className={classes.rowContainer} style={{paddingTop: '3em', display: 'flex'}}>
        
      </Grid>      
    </Grid>
  );
}